<template>
    <modal ref="modalEntregaDinero" titulo="Entrega de dinero" icon="aprobar" no-aceptar adicional="Entregar" @adicional="guardar()">
        <!-- <div class="row mx-0 justify-center mb-3">
            <div class="col-auto d-middle text-general">
                <input v-model="tipo" :value="1" type="radio" class="option-input radio black" name="Efectivo" /> Efectivo
            </div>
            <div class="col-auto d-middle text-general">
                <input v-model="tipo" :value="2" type="radio" class="option-input radio black" name="Efectivo" /> Transferencia bancaria
            </div>
            <div class="col-auto d-middle text-general mt-3">
                <input v-model="tipo" :value="3" type="radio" class="option-input radio black" name="Efectivo" /> Pago en Linea
            </div>
        </div> -->

        <div class="row mx-0 justify-center">
            <div class="col-10">
                <p class="text-general f-14 pl-3">
                    Valor
                </p>
                <money v-model="valor" class="input-money" v-bind="money" />
            </div>
            <div class="col-10 mt-3">
                <p class="text-general f-14 pl-3">
                    Comentario
                </p>
                <el-input v-model="comentario" type="textarea" placeholder="Deja un comentario sobre la entrega" :rows="6" class="w-100" />
            </div>
        </div>
        <div v-if="tipo == 2" class="row mx-0 justify-center mt-3">
            <div class="col-10">
                <p class="text-general f-12 text-center mb-2">
                    Imagen del recibo de transferencia
                </p>
                <div class="row mx-0 justify-center">
                    <slim-cropper ref="fotoReclamo1" :options="slimOptions" class="border cr-pointer" style="height:300px;width:300px;background-color:#F5F5F5;border-radius:6px;" />
                </div>
            </div>
        </div>
        <div v-if="tipo == 3" class="row mx-0 justify-center my-3">
            <div class="col-8 text-general f-15 text-center">
                A continuación la aplicación te llevará a una pagina segura de pago en linea.
            </div>
        </div>
        <pagos-boton-payu ref="botonPayu" />
    </modal>
</template>

<script>
import entregasDineros from '~/services/almacen/entregas_dineros'
import Pagos from '~/services/pagos/pagos'
import {Money} from 'v-money'

export default {
    components:{
        Money,
    },
    data(){
        return {
            valor: '',
            comentario: '',
            tipo: 1,
            money: {
                thousands: '.',
                prefix: '$ ',
                precision: 0,
                masked: false
            },
            slimOptions: {
                ratio: '1:1',
                label:'Subir imagen',
                buttonCancelLabel: 'Cancelar',
                buttonConfirmLabel: 'ok',
                buttonUploadLabel: 'Ok',
            },
        }
    },
    methods: {
        toggle(tipo){
            this.money.precision = this.$tienda.decimales
            this.valor = ''
            this.comentario = ''
            this.tipo = tipo
            this.$refs.modalEntregaDinero.toggle();
        },
        async guardar(){
            try {
                if(!this.valor) return this.notificacion('', 'El campo valor es requerido', 'warning')

                const datos = {
                    tipo: this.tipo,
                    valor: this.valor,
                    comentario: this.comentario,
                }
                
                if (this.tipo == 2){
                    let archivo = this.$refs.fotoReclamo1.instanciaCrop.dataBase64.output
                    datos.imagen = archivo.image
                }

                if(this.tipo === 3){
                    this.initPagoPayU(datos)
                    return
                }

                const { data } = await entregasDineros.guardar(datos)

                if (data.exito == true){
                    this.notificacion('', 'Entrega creada correctamente', 'success')
                    this.$refs.modalEntregaDinero.toggle();
                    this.$refs.fotoReclamo1.instanciaCrop.remove()
                    this.$emit('saved')
                }
            } catch (error){
                this.error_catch(error)
            }
        },
        async initPagoPayU(form){
            try {

                const {data} = await Pagos.datos_entrega(form)
                //return
                this.$refs.botonPayu.initBotonPayu(data.infoPago)
            } catch (e){
                this.error_catch(e)
            }
        }
    }
}
</script>
